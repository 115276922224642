import { AxiosResponse } from "axios";

export const downloadResponse = (res: AxiosResponse, name?: string) => {
	const { data, headers } = res;
	const blob = new Blob([data], {
		type: headers["content-type"],
	});
	const downloadUrl = window.URL.createObjectURL(blob);
	const linkUrl = document.createElement("a");

	const fileType = blob.type.split("/").at(-1);

	linkUrl.download = `${
		name
			? name
			: `${downloadUrl}.${
					fileType ===
					"vnd.openxmlformats-officedocument.wordprocessingml.document"
						? "docx"
						: fileType
			  }`
	}`;
	linkUrl.href = downloadUrl;
	document.body.appendChild(linkUrl);
	linkUrl.click();
	document.body.removeChild(linkUrl);
	linkUrl.remove();
};
