import { titles } from "assets/data/titles";

export function addTitlePage(path: string, title?: string) {
	const routes = titles.filter((item) => path.includes(item.url));

	if (routes.length || path.includes("extra/")) {
		if (routes.length > 1) {
			const splittedPath = path.split("/");

			if (splittedPath.length > 2) {
				const route = routes.filter((item) => {
					return item.url.includes(splittedPath[2]);
				});

				document.title = title ? title : route[0].title;
			}
		} else {
			document.title = title ? title : routes[0].title;
		}
	}
}
