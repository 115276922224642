import {
	UserProfile,
	UserInfo,
	UserAddresses,
	UserPassword,
	UserCompany,
	ApplicationCreatingPage,
	ApplicationDetailPage,
	Login,
	NewPasswordPage,
	PageNotFound,
	PasswordRecovery,
	PrivacyPolicy,
	Registration,
	VerificationPage,
	RolesPage,
	RoleDetailPage,
	NotificationsSettingsPage,
	NotificationDetailPage,
	RelatedApplicationsTab,
	ApplicationsPage,
	ActsPage,
	AgendaPage,
	CandidatesPage,
	СommissionСontrolsPage,
	EmployeesPage,
	ExpertOpinionsPage,
	PresidiumPage,
	MembersPage,
	ExpertOpinionsDetailPage,
	PresidiumDetailPage,
	ApplicationExtraDetailPage,
	ActDetailPage,
	AgendaDetailPage,
	CommissionСontrolsDetailPage,
} from "pages";
import { ApplicationMain } from "components/ApplicationCard";
import { DetailTabelInfo } from "components/DetailTabelInfo";
import { PrivateRoute } from "./PrivateRoute";
import { ProtectedRoute } from "./ProtectedRoute";
import { Redirect } from "./Redirect";
import { UserLayout } from "layouts";

export const basicPaths = [
	{
		path: "/",
		id: 1,
		element: (
			<PrivateRoute>
				<Redirect link={"/applications"} />
			</PrivateRoute>
		),
	},
	{
		path: "/candidates",
		id: 2,
		element: (
			<PrivateRoute>
				<CandidatesPage />
			</PrivateRoute>
		),
	},
	{
		path: "/candidates/:id",
		id: 222,
		element: (
			<PrivateRoute>
				<UserLayout url='candidates' />
			</PrivateRoute>
		),
		children: [
			{
				path: "related-applications",
				id: 2221,
				element: (
					<PrivateRoute>
						<RelatedApplicationsTab url='candidates' />
					</PrivateRoute>
				),
			},
			{
				path: "full-info",
				id: 2222,
				element: (
					<PrivateRoute>
						<DetailTabelInfo />
					</PrivateRoute>
				),
			},
			{
				path: "info",
				id: 2223,
				element: (
					<PrivateRoute forAdmin>
						<UserInfo detail />
					</PrivateRoute>
				),
			},
			{
				path: "addresses",
				id: 2224,
				element: (
					<PrivateRoute forAdmin>
						<UserAddresses detail />
					</PrivateRoute>
				),
			},
			{
				path: "company",
				id: 2225,
				element: (
					<PrivateRoute forAdmin>
						<UserCompany detail />
					</PrivateRoute>
				),
			},
			{
				path: "password",
				id: 2226,
				element: (
					<PrivateRoute forAdmin>
						<UserPassword detail />
					</PrivateRoute>
				),
			},
		],
	},

	{
		path: "/members",
		id: 3,
		element: (
			<PrivateRoute>
				<MembersPage />
			</PrivateRoute>
		),
	},
	{
		path: "/members/:id",
		id: 31,
		element: (
			<PrivateRoute>
				<UserLayout url='members' />
			</PrivateRoute>
		),
		children: [
			{
				path: "related-applications",
				id: 331,
				element: (
					<PrivateRoute>
						<RelatedApplicationsTab url='members' />
					</PrivateRoute>
				),
			},
			{
				path: "full-info",
				id: 332,
				element: (
					<PrivateRoute>
						<DetailTabelInfo />
					</PrivateRoute>
				),
			},
			{
				path: "info",
				id: 2223,
				element: (
					<PrivateRoute forAdmin>
						<UserInfo detail />
					</PrivateRoute>
				),
			},
			{
				path: "addresses",
				id: 2224,
				element: (
					<PrivateRoute forAdmin>
						<UserAddresses detail />
					</PrivateRoute>
				),
			},
			{
				path: "company",
				id: 2225,
				element: (
					<PrivateRoute forAdmin>
						<UserCompany detail />
					</PrivateRoute>
				),
			},
			{
				path: "password",
				id: 2226,
				element: (
					<PrivateRoute forAdmin>
						<UserPassword detail />
					</PrivateRoute>
				),
			},
		],
	},
	{
		path: "/login",
		id: 4,
		element: (
			<ProtectedRoute>
				<Login />
			</ProtectedRoute>
		),
	},
	{
		path: "/registration",
		id: 5,
		element: (
			<ProtectedRoute>
				<Registration />
			</ProtectedRoute>
		),
	},
	{
		path: "/privacy-policy",
		id: 6,
		element: <PrivacyPolicy />,
	},
	{
		path: "/login/password-pecovery",
		id: 7,
		element: (
			<ProtectedRoute>
				<PasswordRecovery />
			</ProtectedRoute>
		),
	},
	{
		path: "/create-password",
		id: 8,
		element: (
			<ProtectedRoute>
				<NewPasswordPage />
			</ProtectedRoute>
		),
	},
	{
		path: "/verification",
		id: 9,
		element: (
			<ProtectedRoute>
				<VerificationPage />
			</ProtectedRoute>
		),
	},
	{
		path: "/applications",
		id: 10,
		element: (
			<PrivateRoute>
				<ApplicationsPage />
			</PrivateRoute>
		),
	},
	{
		path: "/applications/:id",
		id: 13,
		element: (
			<PrivateRoute>
				<ApplicationDetailPage />
			</PrivateRoute>
		),
		children: [
			{
				path: "common",
				id: 131,
				element: (
					<PrivateRoute>
						<ApplicationMain />
					</PrivateRoute>
				),
			},
			{
				path: ":type",
				id: 132,
				element: (
					<PrivateRoute>
						<DetailTabelInfo />
					</PrivateRoute>
				),
			},
		],
	},
	{
		path: "/applications/creating",
		id: 11,
		element: (
			<PrivateRoute>
				<ApplicationCreatingPage />
			</PrivateRoute>
		),
	},
	{
		path: "/profile",
		id: 12,
		element: (
			<PrivateRoute>
				<UserProfile />
			</PrivateRoute>
		),
		children: [
			{
				path: "info",
				id: 121,
				element: (
					<PrivateRoute>
						<UserInfo />
					</PrivateRoute>
				),
			},
			{
				path: "addresses",
				id: 122,
				element: (
					<PrivateRoute>
						<UserAddresses />
					</PrivateRoute>
				),
			},
			{
				path: "company",
				id: 123,
				element: (
					<PrivateRoute>
						<UserCompany />
					</PrivateRoute>
				),
			},
			{
				path: "password",
				id: 124,
				element: (
					<PrivateRoute>
						<UserPassword />
					</PrivateRoute>
				),
			},
		],
	},
	{
		path: "/staff",
		id: 13,
		element: (
			<PrivateRoute>
				<EmployeesPage />
			</PrivateRoute>
		),
	},
	{
		path: "/staff/:id",
		id: 131,
		element: (
			<PrivateRoute>
				<UserLayout url='staff' />
			</PrivateRoute>
		),
		children: [
			{
				path: "related-applications",
				id: 131,
				element: (
					<PrivateRoute>
						<RelatedApplicationsTab url='staff' />
					</PrivateRoute>
				),
			},
			{
				path: "full-info",
				id: 132,
				element: (
					<PrivateRoute>
						<DetailTabelInfo />
					</PrivateRoute>
				),
			},
			{
				path: "info",
				id: 2223,
				element: (
					<PrivateRoute forAdmin>
						<UserInfo detail admin />
					</PrivateRoute>
				),
			},
			{
				path: "password",
				id: 2226,
				element: (
					<PrivateRoute forAdmin>
						<UserPassword detail admin />
					</PrivateRoute>
				),
			},
		],
	},
	{
		path: "/expert-opinions",
		id: 14,
		element: (
			<PrivateRoute>
				<ExpertOpinionsPage />
			</PrivateRoute>
		),
	},
	{
		path: "/expert-opinions/:id",
		id: 141,
		element: (
			<PrivateRoute>
				<ExpertOpinionsDetailPage />
			</PrivateRoute>
		),
		children: [
			{
				path: "common",
				id: 1411,
				element: (
					<PrivateRoute>
						<ApplicationMain />
					</PrivateRoute>
				),
			},
			{
				path: "info",
				id: 1412,
				element: (
					<PrivateRoute>
						<DetailTabelInfo />
					</PrivateRoute>
				),
			},
		],
	},
	{
		path: "/protocols-presidium",
		id: 15,
		element: (
			<PrivateRoute>
				<PresidiumPage />
			</PrivateRoute>
		),
	},
	{
		path: "/protocols-presidium/:id",
		id: 151,
		element: (
			<PrivateRoute>
				<PresidiumDetailPage />
			</PrivateRoute>
		),
		children: [
			{
				path: "common",
				id: 1511,
				element: (
					<PrivateRoute>
						<ApplicationMain />
					</PrivateRoute>
				),
			},
			{
				path: "info",
				id: 1512,
				element: (
					<PrivateRoute>
						<DetailTabelInfo />
					</PrivateRoute>
				),
			},
		],
	},
	{
		path: "/protocols-control-commission",
		id: 16,
		element: (
			<PrivateRoute>
				<СommissionСontrolsPage />
			</PrivateRoute>
		),
	},
	{
		path: "/protocols-control-commission/:id",
		id: 161,
		element: (
			<PrivateRoute>
				<CommissionСontrolsDetailPage />
			</PrivateRoute>
		),
		children: [
			{
				path: "common",
				id: 1611,
				element: (
					<PrivateRoute>
						<ApplicationMain />
					</PrivateRoute>
				),
			},
			{
				path: "info",
				id: 1612,
				element: (
					<PrivateRoute>
						<DetailTabelInfo />
					</PrivateRoute>
				),
			},
		],
	},
	{
		path: "/questions-agenda",
		id: 17,
		element: (
			<PrivateRoute>
				<AgendaPage />
			</PrivateRoute>
		),
	},
	{
		path: "/questions-agenda/:id",
		id: 171,
		element: (
			<PrivateRoute>
				<AgendaDetailPage />
			</PrivateRoute>
		),
		children: [
			{
				path: "common",
				id: 1711,
				element: (
					<PrivateRoute>
						<ApplicationMain />
					</PrivateRoute>
				),
			},
			{
				path: "info",
				id: 1712,
				element: (
					<PrivateRoute>
						<DetailTabelInfo />
					</PrivateRoute>
				),
			},
		],
	},
	{
		path: "/aсts-issued",
		id: 18,
		element: (
			<PrivateRoute>
				<ActsPage />
			</PrivateRoute>
		),
	},
	{
		path: "/aсts-issued/:id",
		id: 181,
		element: (
			<PrivateRoute>
				<ActDetailPage />
			</PrivateRoute>
		),
		children: [
			{
				path: "common",
				id: 1811,
				element: (
					<PrivateRoute>
						<ApplicationMain />
					</PrivateRoute>
				),
			},
			{
				path: ":type",
				id: 1812,
				element: (
					<PrivateRoute>
						<DetailTabelInfo />
					</PrivateRoute>
				),
			},
		],
	},
	{
		path: "/extra/:id",
		id: 13,
		element: (
			<PrivateRoute>
				<ApplicationExtraDetailPage />
			</PrivateRoute>
		),
		children: [
			{
				path: "common",
				id: 131,
				element: (
					<PrivateRoute>
						<ApplicationMain />
					</PrivateRoute>
				),
			},
			{
				path: ":type",
				id: 132,
				element: (
					<PrivateRoute>
						<DetailTabelInfo />
					</PrivateRoute>
				),
			},
		],
	},
	{
		path: "/roles",
		id: 19,
		element: (
			<PrivateRoute>
				<RolesPage />
			</PrivateRoute>
		),
	},
	{
		path: "/roles/:id",
		id: 191,
		element: (
			<PrivateRoute>
				<RoleDetailPage />
			</PrivateRoute>
		),
	},
	{
		path: "/notifications",
		id: 20,
		element: (
			<PrivateRoute>
				<NotificationsSettingsPage />
			</PrivateRoute>
		),
	},
	{
		path: "/notifications/:id",
		id: 201,
		element: (
			<PrivateRoute>
				<NotificationDetailPage />
			</PrivateRoute>
		),
	},
	{
		path: "*",
		id: 0,
		element: <PageNotFound />,
	},
];

export default basicPaths;
