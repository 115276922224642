import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { Typography } from "@mui/material";
import { getPathByType, getApiByType } from "utils/helpers";
import { baseApiUrl } from "utils/constants";
import { useRequest, useIsAppType, useIsRole } from "utils/hooks";
import {
	ApplicationProps,
	ApplicationType,
	InputOnChange,
	StatusTypes,
	ResultProps,
} from "utils/types";
import { Button, Select } from "components/FormElements";
import { Popup } from "components/Popup";
import { TextButton } from "../ApplicationMain/styles";
import { PopupContainer, Label } from "./styles";
import { useUserId } from "utils/store";
import { AdditionalApplication } from "components/ApplicationCreationPopups";

interface ButtonPreparationsProps {
	id?: number;
	applications: ApplicationProps[];
	type?: ApplicationType;
	subType?: string;
	curatorId?: number;
	status?: StatusTypes;
	paid?: boolean;
	result?: ResultProps;
	registryNumber?: string | null;
}

export const ButtonPreparations = ({
	id,
	applications,
	type,
	curatorId,
	subType,
	status,
	paid,
	result,
	registryNumber,
}: ButtonPreparationsProps) => {
	const [isLoader, setIsLoader] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const [agendaType, setAgendaType] = useState("");
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const navigate = useNavigate();
	const request = useRequest;
	const userId = useUserId();
	const { isHeadExpert, isCurator, isHeadAccountant } = useIsRole();
	const { isODO, isPrimary, isExtract, isTermination, isVV, isExtra } =
		useIsAppType(type);
	const isSigned =
		getApplicationByType("expert_opinion")?.status.type ===
		"expert_opinion_signed";
	const [round, setRound] = useState(1);

	const buttonProps = {
		preloader: { loading: isLoader },
		disabled: isLoader,
	};

	useEffect(() => {
		if (applications.length !== 0) {
			const exp = getApplicationsByType("expert_opinion");
			setRound(exp.length);
		}
	}, [applications]);

	const sendRequest = (appType: string, data: {}) => {
		setIsLoader(true);
		const params = {
			method: "POST",
			data,
		};
		request(`${baseApiUrl}/${getApiByType(appType)}/`, params).then((res) => {
			setTimeout(() => {
				const { data, status } = res;
				closeSnackbar();
				setIsLoader(false);
				if (status >= 200 && status < 300) {
					setShowModal(false);
					navigate(`${getPathByType(appType)}${data.id}`);
				} else {
					const { errors } = data;
					if (errors && errors.length > 0) {
						enqueueSnackbar("При отправке запроса возникла ошибка!", {
							variant: "error",
							description: errors[0] ? errors[0].message : "",
							autoHideDuration: null,
						});
					}
				}
			}, 250);
		});
	};

	function getApplicationByType(neededType: ApplicationType) {
		return applications.find(({ type }) => type === neededType);
	}

	function getApplicationsByType(neededType: ApplicationType) {
		return applications.filter(({ type }) => type === neededType);
	}

	const allApplicationsByTypeHasStatus = (
		neededType: ApplicationType,
		neededStatus: StatusTypes,
	) => {
		const apps = getApplicationsByType(neededType);
		return apps.every(({ status }) => status.type === neededStatus);
	};

	const handleChange: InputOnChange = ({ value, field, id }) => {
		setAgendaType(String(value));
	};

	const createAgenda = () => {
		const type = JSON.parse(agendaType)?.value;
		sendRequest("agenda", { agenda: { application: id, type } });
	};

	return (
		<>
			{(status === "application_preparing_documents" ||
				status === "application_extra_preparing_documents") && (
				<>
					{isHeadExpert && (
						<>
							{((applications.length === 0 &&
								!isODO &&
								!isVV &&
								!isTermination) ||
								(isPrimary &&
									result?.type === "postponed" &&
									applications.length === 3 * round)) && (
								<Button
									onClick={() =>
										sendRequest("expert_opinion", {
											expertOpinion: { application: id },
										})
									}
									{...buttonProps}>
									Создать ЭЗ
								</Button>
							)}
							{((applications.length === 1 &&
								!isODO &&
								!isVV &&
								!isTermination &&
								getApplicationByType("expert_opinion")?.status.type ==
									"expert_opinion_ready") ||
								isSigned ||
								(isPrimary &&
									result?.type === "postponed" &&
									allApplicationsByTypeHasStatus(
										"expert_opinion",
										"expert_opinion_ready",
									) &&
									applications.length === 3 * round - 2)) && (
								<div>
									<Button
										onClick={() =>
											sendRequest("commission_control", {
												commissionControl: { applications: [id] },
											})
										}
										{...buttonProps}
										disabled={isSigned}>
										Создать ПКК
									</Button>
									{isSigned && (
										<TextButton>Ожидание подписи Директора</TextButton>
									)}
								</div>
							)}

							{((applications.length === 2 &&
								getApplicationByType("commission_control")?.status.type ==
									"commission_control_ready") ||
								(applications.length === 0 &&
									(isODO || isVV || isTermination)) ||
								(isPrimary &&
									((applications.length === 3 &&
										((paid && result?.type === "accept") ||
											getApplicationByType("agenda")?.status.type ===
												"agenda_canceled")) ||
										(result?.type === "postponed" &&
											allApplicationsByTypeHasStatus(
												"commission_control",
												"commission_control_ready",
											) &&
											applications.length === round * 3 - 1))) ||
								(isExtra && applications.length === 1)) && (
								<Button
									onClick={() =>
										isPrimary && !paid && result?.type !== "accept"
											? setShowModal(true)
											: sendRequest("agenda", {
													agenda: {
														[isExtra ? "application_extra" : "application"]: id,
													},
											  })
									}
									{...buttonProps}>
									Создать ВП
								</Button>
							)}
						</>
					)}

					{isCurator && curatorId === userId && (
						<>
							{isExtract && applications.length === 0 && (
								<Button
									onClick={() =>
										sendRequest("act", {
											id,
										})
									}
									{...buttonProps}>
									Создать Акт для выписки
								</Button>
							)}
						</>
					)}

					<Popup active={showModal} afterClose={() => setShowModal(false)}>
						<PopupContainer
							component='form'
							onSubmit={(e) => {
								e.preventDefault();
								createAgenda();
							}}>
							<Typography component='h2' variant='h2' id='modal-title'>
								Создание ВП
							</Typography>
							<Label>Тип ВП</Label>
							<Select
								id={1}
								value={agendaType}
								required
								field='type'
								placeholder='Выберите тип'
								replaceName
								options={[
									{
										value: "accept",
										name: "Прием в членство",
									},
									{
										value: "deny",
										name: "Отказ в членстве",
									},
								]}
								onChange={handleChange}
							/>
							<Button type='submit' {...buttonProps}>
								Сохранить
							</Button>
						</PopupContainer>
					</Popup>
				</>
			)}

			{isHeadAccountant &&
				status === "application_closed" &&
				paid &&
				result?.type === "accept" &&
				registryNumber &&
				!getApplicationByType("extra") && (
					<>
						<Button onClick={() => setShowModal(true)} {...buttonProps}>
							Добавить дополнительную заявку
						</Button>
						<Popup active={showModal} afterClose={() => setShowModal(false)}>
							<AdditionalApplication applicationId={id} />
						</Popup>
					</>
				)}
		</>
	);
};

export default ButtonPreparations;
