import { useState, useEffect } from "react";
import { memberRoleId, candidateRoleId } from "utils/constants";
import { navigationGenerator } from "utils/helpers/generators";
import { navigation, navigation2 } from "assets/data/navigation";
import { useUser, useUserRole } from "utils/store";
import { Logotype } from "components/Logotype";
import { NavMenuLink } from "./NavMenuLink";
import { Wrapper, Navigation } from "./styles";

export const NavMenu = () => {
	const [isEmployee, setIsEmployee] = useState(false);
	const user = useUser();
	const roles = useUserRole();
	const list = isEmployee ? navigation : navigation2;

	useEffect(() => {
		if (roles)
			roles.map(({ id }) =>
				id !== candidateRoleId && id !== memberRoleId
					? setIsEmployee(true)
					: setIsEmployee(false),
			);
	}, [roles]);

	return (
		<Wrapper>
			<Logotype />
			{user?.navigation ? (
				<Navigation>
					{navigationGenerator(list, user.navigation).map(
						({ id, ...other }) => (
							<NavMenuLink key={id} id={id} {...other} />
						),
					)}
				</Navigation>
			) : null}
		</Wrapper>
	);
};
