import { PaidProps } from "utils/types";
import { CloseIcon, CheckIcon } from "assets/icons";
import { Container, IconWrapper, Text } from "./styles";

export const PaidResult = ({ result }: PaidProps) => {
	return (
		<Container result={result}>
			<IconWrapper result={result}>
				{result ? <CheckIcon /> : <CloseIcon />}
			</IconWrapper>
			<Text>{result ? "Оплачен" : "Не оплачен"}</Text>
		</Container>
	);
};
