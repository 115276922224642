import { useState, useLayoutEffect, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { SwitchTransition, CSSTransition } from "react-transition-group";
import { useSnackbar } from "notistack";
import { Typography } from "@mui/material";
import { useRequest } from "utils/hooks";
import { ErrorResult } from "components/ErrorResult";
import { NoАccess } from "components/NoАccess";
import { Preloader } from "components/Preloader";
import { Button, Checkbox } from "components/FormElements";
import { PermissionsCreateOrDelete } from "components/PermissionsCreateOrDelete";
import { BackIconButton } from "components/BackIconButton";
import { WhiteLayer } from "styles/common";
import { baseApiUrl } from "utils/constants";
import {
	InputOnChange,
	NotificationSettings,
	NotificationOption,
} from "utils/types";
import {
	Сontent,
	NotFoundWrapper,
	Permissions,
	Header,
	TitleSkeleton,
	ButtonWrapper,
	PermissionsWrapper,
} from "./styles";

export const NotificationDetailPage = () => {
	const [isLoading, setIsLoading] = useState(true);
	const [isError, setIsError] = useState(null);
	const [isNoAccess, setIsNoAccess] = useState(false);
	const [permissions, setPermissions] = useState<NotificationSettings | null>(
		null,
	);
	const request = useRequest;
	const { pathname } = useLocation();
	const linkParamId = pathname.split("/").at(-1);
	const apiUrl = `${baseApiUrl}/admin/notifications/settings/${linkParamId}/`;
	const [permissiomsOptions, setPermissionsOptions] = useState<
		NotificationOption[]
	>([]);
	const [savedData, setSavedData] = useState<NotificationSettings | null>(null);
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();

	useLayoutEffect(() => {
		window.scrollTo({ top: 0, behavior: "smooth" });
		request(apiUrl).then((res) => {
			const { data, status } = res;
			if (status < 400) {
				setPermissions(data);
				setSavedData(data);
			} else {
				if (status === 404 || status === 500) {
					setIsError(status);
				} else {
					setIsNoAccess(true);
				}
			}
			setTimeout(() => {
				setIsLoading(false);
			}, 250);
		});
	}, []);

	useEffect(() => {
		if (permissions) {
			setPermissionsOptions(permissions.options);
		}
	}, [permissions]);

	const sendRequest = () => {
		closeSnackbar();
		request(apiUrl, { method: "PUT", data: permissions }).then((res) => {
			const { data, status } = res;
			if (status >= 200 && status < 300) {
				setPermissions(data);
				setSavedData(data);
			} else {
				const { errors } = data;

				enqueueSnackbar(
					"При отправке запроса, что-то пошло не так. Попробуйте снова",
					{
						variant: "error",
						description: errors[0] ? errors[0].message : "",
						autoHideDuration: null,
					},
				);
			}
			setTimeout(() => {
				setIsLoading(false);
			}, 250);
		});
	};

	const cancel = () => {
		if (savedData) {
			console.log("cancel", permissions, savedData);
			setPermissionsOptions(savedData.options);
		}
	};

	useEffect(() => {
		console.log("permissions", permissions);
	}, [permissions]);

	const changeNoticeOptions: InputOnChange = ({ field, value }) => {
		const newInputProps = permissiomsOptions.map((item) =>
			field === item.codeName
				? {
						...item,
						options: item.options.map(
							(
								item, //@ts-ignore
							) => (item.codeName === value.codeName ? value : item),
						),
				  }
				: item,
		); //@ts-ignore
		setPermissionsOptions(newInputProps);
	};

	const changeNotice: InputOnChange = ({ field, value }) => {
		const options = permissiomsOptions.map((item) => {
			//@ts-ignore
			if (item?.codeName == field) {
				return {
					...item,
					checked: value,
				};
			} else {
				return item;
			}
		}); //@ts-ignore
		setPermissionsOptions(options);
	};

	return (
		<WhiteLayer>
			{isError || isNoAccess ? (
				<NotFoundWrapper>
					{isError ? (
						<ErrorResult code={isError} url={`/`} />
					) : (
						<NoАccess url={`/`} />
					)}
				</NotFoundWrapper>
			) : (
				<>
					<Header>
						<BackIconButton />
						{permissions && !isLoading ? (
							<Typography component='h1' variant='h2'>
								{permissions.name}
							</Typography>
						) : (
							<TitleSkeleton variant='rounded' />
						)}
					</Header>
					<Сontent>
						<SwitchTransition mode='out-in'>
							<CSSTransition
								key={permissions ? "content" : "loader"}
								timeout={300}
								classNames='fade'>
								{permissions && !isLoading ? (
									<Permissions>
										{permissiomsOptions.map((option) => (
											<PermissionsWrapper>
												<Checkbox
													id={option.id}
													field={option.codeName}
													label={option.name}
													checked={option.checked}
													onChange={changeNotice}
													className='permission-title'
												/>
												{option.options.map((item) => (
													<PermissionsCreateOrDelete
														permission={item}
														fieldType={
															item.codeName === "receive_notice_type" &&
															option.codeName !== "deadline_object"
																? "radio"
																: "chekbox"
														}
														setOptions={(value) => {
															//@ts-ignore
															changeNoticeOptions({
																field: option.codeName,
																value,
															});
														}}
														disabled={!option.checked}
													/>
												))}
											</PermissionsWrapper>
										))}

										<ButtonWrapper>
											<Button
												preloader={{ loading: isLoading }}
												disabled={isLoading}
												onClick={sendRequest}>
												Сохранить изменения
											</Button>
											<Button variant='outlined' onClick={cancel}>
												Отменить
											</Button>
										</ButtonWrapper>
									</Permissions>
								) : (
									<Preloader active={isLoading} />
								)}
							</CSSTransition>
						</SwitchTransition>
					</Сontent>
				</>
			)}
		</WhiteLayer>
	);
};

export default NotificationDetailPage;
