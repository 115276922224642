import { useLayoutEffect } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getUserIdByJwt } from "utils/helpers";
import { useUserUpdate } from "utils/hooks";
import { setUser } from "utils/store";

import { basicPaths } from "./routes";
import { useUser } from "utils/store/hooks";

export const Navigation = () => {
	const { pathname } = useLocation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const user = useUser();
	const userUpdate = useUserUpdate;

	useLayoutEffect(() => {
		if (!user) {
			userUpdate(getUserIdByJwt()).then(({ data, status }) => {
				if (status >= 200 && status < 300) {
					dispatch(setUser({ ...data }));
				} else {
					if (pathname !== "/create-password") {
						navigate("/login");
					}
				}
			});
		}
	}, []);

	return (
		<Routes>
			{basicPaths.map(({ id, path, element, children }) =>
				children ? (
					<Route key={id} path={path} element={element}>
						<Route index element={children[0].element} />
						{children.map((child) => (
							<Route key={child.id} path={child.path} element={child.element} />
						))}
					</Route>
				) : (
					<Route key={id} path={path} element={element} />
				),
			)}
		</Routes>
	);
};
