import { useState, useEffect, useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { useUser, setUserName, useUserDetail } from "utils/store";
import { baseApiUrl, candidateRoleId, memberRoleId } from "utils/constants";
import { useRequest } from "utils/hooks";
import {
	addedErrorOnSections,
	hideErrorOnSections,
	getFormValues,
} from "utils/helpers";
import {
	InputDataProps,
	InputOnChange,
	FormSectionDataProps,
	SelectOptionProps,
	ResponsibilityLevelItemProps,
	RoleItemProps,
} from "utils/types";
import { ErrorResult } from "components/ErrorResult";
import { Form } from "components/Form";
import { FormSection } from "components/FormSection";
import { Button } from "components/FormElements";
import { NoАccess } from "components/NoАccess";
import { Preloader } from "components/Preloader";
import { ButtonContainer, FormContent, NotFoundWrapper } from "./styles";

interface ProfileFormProps {
	name: string;
	content: FormSectionDataProps[];
	link?: string;
	method?: string;
	detail?: boolean;
	admin?: boolean;
	withLevel?: boolean;
}

export const ProfileForm = ({
	name,
	content,
	link,
	method,
	detail,
	admin,
	withLevel,
}: ProfileFormProps) => {
	const [savedData, setSavedData] = useState<FormSectionDataProps[]>([]);
	const [formValues, setFormValues] = useState<any>();
	const [sectionsProps, setSectionsProps] =
		useState<FormSectionDataProps[]>(content);
	const [preloader, setPreloader] = useState(false);
	const [isHasChanges, setIsHasChanges] = useState(false);
	const [loading, setLoading] = useState(true);
	const [isRespLoading, setIsRespLoading] = useState(true);
	const [isRoleLoading, setIsRoleLoading] = useState(false);
	const [responsibility, setResponsibility] = useState<{
		levelManyContract: SelectOptionProps[];
		levelOneContract: SelectOptionProps[];
	}>();
	const dispatch = useDispatch();
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const user = useUser();
	const { pathname } = useLocation();
	const request = useRequest;
	const splitedPath = pathname.split("/");
	const userId = detail
		? splitedPath.length === 3
			? splitedPath.at(-1)
			: splitedPath.at(-2)
		: user?.id;
	const url = `${baseApiUrl}${link ? link : `/users/sro/${userId}/${name}/`}`;
	const respUrl = `${baseApiUrl}/responsibility/`;
	const [isError, setIsError] = useState(null);
	const userDetail = useUserDetail();
	const [rolesList, setRolesList] = useState<SelectOptionProps[] | null>(null);

	useLayoutEffect(() => {
		if (withLevel) {
			request(respUrl).then((res) => {
				const {
					data: {
						responsibilityLevelManyContract,
						responsibilityLevelOneContract,
					},
				} = res;
				setResponsibility({
					levelManyContract: responsibilityLevelManyContract.map(
						({ id, label }: ResponsibilityLevelItemProps) => ({
							value: id,
							name: label,
						}),
					),
					levelOneContract: responsibilityLevelOneContract.map(
						({ id, label }: ResponsibilityLevelItemProps) => ({
							value: id,
							name: label,
						}),
					),
				});
				setTimeout(() => {
					setIsRespLoading(false);
				}, 250);
			});
		} else {
			setIsRespLoading(false);
		}
		if (admin && detail) {
			setIsRoleLoading(true);
			request(`${baseApiUrl}/admin/users/roles/`).then((res) => {
				const {
					data: { results },
					status,
				} = res;
				if (status < 400) {
					if (results && results.length > 0) {
						const rolesRes: SelectOptionProps[] = results
							.filter(
								(item: RoleItemProps) =>
									item.id !== candidateRoleId && item.id !== memberRoleId,
							)
							.map(({ name, id }: RoleItemProps) => ({ id, name, value: id }));

						setRolesList(rolesRes);
					}
				}
			});
		} else {
			setRolesList(null);
		}
	}, []);

	useEffect(() => {
		if (rolesList && userDetail) {
			const newSectionsProps = sectionsProps.map((section) =>
				section.id === "roles"
					? {
							...section,
							inputs: section.inputs.map((item) =>
								item.field === "roles"
									? {
											...item,
											options: rolesList,
											value: userDetail.role.map(({ name, id }) =>
												JSON.stringify({ name, value: id }),
											),
									  }
									: item,
							),
					  }
					: section,
			);
			setSavedData(newSectionsProps);
			setSectionsProps(newSectionsProps);
			setIsRoleLoading(false);
		}
	}, [rolesList, userDetail]);

	useEffect(() => {
		if (!loading) {
			setFormValues(getFormValues(sectionsProps));
		}
	}, [sectionsProps, loading]);

	useEffect(() => {
		if (name !== "password") {
			if (!isRespLoading && !isRoleLoading && user?.id) getUserData();
		} else {
			setTimeout(() => {
				setLoading(false);
			}, 250);
		}
	}, [isRespLoading, isRoleLoading, user?.id]);

	const changeFormFields = (
		inputsList: InputDataProps[],
		orgForm: string | number,
		reset: boolean = false,
	) => {
		const isLegalOrgForm = orgForm == 1;

		const changebleProps: { [key: string]: any } = {
			"10": isLegalOrgForm
				? {
						minLength: 10,
						mask: "9999999999",
				  }
				: { minLength: 12, mask: "999999999999" },
			"12": isLegalOrgForm
				? {
						field: "fullName",
						label: "Полное наименование юридического лица",
				  }
				: { field: "entrepreneursName", label: "ФИО" },
			"14": isLegalOrgForm
				? {
						field: "shortName",
						label: "Сокращенное наименование юридического лица",
						type: undefined,
				  }
				: { field: "birthDate", label: "Дата рождения", type: "date" },

			"15": isLegalOrgForm
				? {
						label: "ОГРН",
						minLength: 13,
						mask: "9999999999999",
				  }
				: {
						label: "ОГРНИП",
						minLength: 15,
						mask: "999999999999999",
				  },
		};

		let newInputList: InputDataProps[] = [];

		inputsList.map((item) => {
			let newItem = item;
			if (item.changeable && changebleProps[item.id]) {
				newItem = {
					...item,
					...changebleProps[item.id],
					value: reset ? "" : item.value,
				};
			}

			newInputList.push(newItem);
		});

		return newInputList;
	};

	const getUserData = () => {
		console.log(user, url);
		request(url)
			.then((res) => {
				const { data, status } = res;
				if (status < 400) {
					let newInputsProps: InputDataProps[] = [];
					let newSectionsProps: FormSectionDataProps[] = [];
					let isLocationMatches: any = data["locationActual"] ? false : true;
					sectionsProps.map(({ inputs, id, ...other }) => {
						let newInputs: InputDataProps[] = [];
						const inputsList =
							id === "company"
								? // @ts-ignore
								  changeFormFields(inputs, data[id]["organizationForm"])
								: inputs;
						inputsList.map(({ field, value, ...other }) => {
							let newItem = { field, value, ...other };

							newItem.value = data[id]
								? data[id][field]
									? data[id][field]
									: ""
								: value;
							if (field === "locationMatches")
								newItem.value = isLocationMatches;
							if (isLocationMatches && id === "locationActual")
								newItem = { ...newItem, disabled: isLocationMatches };
							if (
								(field === "responsibilityLevelManyContract" ||
									field === "responsibilityLevelOneContract") &&
								responsibility
							) {
								let resp =
									field === "responsibilityLevelManyContract"
										? responsibility.levelManyContract
										: responsibility.levelOneContract;

								let selected = resp.find(
									({ name }) => name === data["company"][field],
								);
								const selectVal = selected ? selected.name : [];

								newItem = {
									...newItem,
									options: resp,
									value: selectVal,
								};
							}
							newInputsProps.push(newItem);
							newInputs.push(newItem);
						});
						newSectionsProps.push({
							...other,
							id,
							inputs: id === "roles" ? inputs : newInputs,
						});
					});
					setSavedData(newSectionsProps);
					setSectionsProps(newSectionsProps);
				} else {
					setIsError(status);
				}
			})
			.finally(() => {
				setTimeout(() => {
					setLoading(false);
				}, 250);
			});
	};

	const sendForm = (data: any) => {
		let newSections = [];
		closeSnackbar();
		if (data.errors) {
			newSections = addedErrorOnSections(data.errors, sectionsProps);
			setSectionsProps(newSections);
		} else {
			newSections = hideErrorOnSections(sectionsProps);
			setIsHasChanges(false);
			setSavedData(newSections);
			enqueueSnackbar("Данные успешно изменены!", {
				variant: "success",
			});
			if (name === "info") {
				dispatch(setUserName(String(newSections[0].inputs[0].value)));
			}
		}
		setTimeout(() => {
			setPreloader(false);
		}, 500);
	};

	const resetForm = () => {
		let totalSavedData = savedData;
		if (name === "company") {
			const inputsList = changeFormFields(
				totalSavedData[0].inputs, // @ts-ignore
				totalSavedData[0].inputs[1].value,
			);

			totalSavedData[0] = { ...totalSavedData[0], inputs: inputsList };
		}
		setIsHasChanges(false);
		setSectionsProps(totalSavedData);
	};

	const comparePassword = (newInputProps: InputDataProps[]) => {
		let newProps = newInputProps;
		const noMatch = newProps[1].value === newProps[2].value;

		newProps[1] = {
			...newProps[1],
			invalid: !noMatch,
		};
		newProps[2] = {
			...newProps[2],
			invalid: !noMatch,
			helperText: "Пароли не совпадают",
		};

		setIsHasChanges(noMatch);

		return newProps;
	};

	const changeValue: InputOnChange = ({ field, value, id, suggestion }) => {
		const orgFormNameField = "organizationForm";
		const isSuggestion = suggestion && suggestion[orgFormNameField];
		let newInputsProps: InputDataProps[] = [];
		let newSectionsProps: FormSectionDataProps[] = [];
		let isLocationMatches: any = false;

		console.log(value);

		sectionsProps.map((section) => {
			let newInputs: InputDataProps[] = [];

			const inputsList =
				section.id === "company" && (field === orgFormNameField || isSuggestion)
					? changeFormFields(
							section.inputs,
							isSuggestion ? suggestion[orgFormNameField] : value,
					  )
					: section.inputs;

			inputsList.map((item) => {
				let newItem = item;

				if (field === item.field && id === `${item.field}-${item.id}`) {
					newItem = { ...item, value: value, invalid: false };
				}

				if (item.field === "locationMatches") {
					isLocationMatches = newItem.value;
				}

				if (section.id === "locationActual") {
					newItem = { ...newItem, disabled: isLocationMatches };
					if (isLocationMatches) {
						newItem.value = "";
					}
				}

				if (suggestion) {
					if (
						(section.dadata && id.toString().includes(section.dadata)) ||
						(suggestion["organizationForm"] &&
							section.id !== "locationActual" &&
							section.id !== "contactPerson")
					) {
						newItem.value =
							suggestion[item.field] !== undefined
								? suggestion[item.field]
								: item.value;
					}
				}

				newInputs.push(newItem);
				newInputsProps.push(newItem);
			});

			const totalsInputs =
				name === "password" ? comparePassword(newInputs) : newInputs;

			newSectionsProps.push({ ...section, inputs: totalsInputs });
		});

		setSectionsProps(newSectionsProps);
		setIsHasChanges(true);
	};

	return isError ? (
		<NotFoundWrapper>
			{isError === 403 ? (
				<NoАccess url='/' />
			) : (
				<ErrorResult code={isError} url='/' buttonName='На главную' />
			)}
		</NotFoundWrapper>
	) : (
		<Form
			url={url}
			method={method ? method : "PUT"}
			values={formValues}
			afterSubmit={sendForm}
			beforeSubmit={() => setPreloader(true)}
			button={
				<ButtonContainer>
					<Button
						type='submit'
						preloader={{ loading: preloader }}
						disabled={preloader || !isHasChanges}>
						Сохранить изменения
					</Button>
					<Button
						variant='outlined'
						disabled={!isHasChanges}
						onClick={resetForm}>
						Отменить
					</Button>
				</ButtonContainer>
			}>
			<Preloader active={loading}>
				<FormContent>
					{!loading &&
						sectionsProps.map(({ id, ...other }, index) => (
							<FormSection
								key={id}
								id={id}
								className={admin && name === "info" ? "staff" : name}
								headlineSize='h3'
								index={index}
								{...other}
								onChange={changeValue}
							/>
						))}
				</FormContent>
			</Preloader>
		</Form>
	);
};

export default ProfileForm;
