import { UserProfile, UserDetailPage } from "pages";
import { useUserRole } from "utils/store";
import { adminRoleId } from "utils/constants";

import { useIsRole } from "utils/hooks";

export const UserLayout = ({ url }: { url: string }) => {
	const roles = useUserRole();

	const isAdmin = roles?.find(({ id }) => id === adminRoleId);

	return (
		<>
			{roles && (
				<>
					{isAdmin ? (
						<UserProfile detail admin={url === "staff"} />
					) : (
						<UserDetailPage url={url} api='users/sro' />
					)}
				</>
			)}
		</>
	);
};
