import { useLocation } from "react-router-dom";
import { ListItem } from "components/ListItem";
import { UserItemProps } from "utils/types";
import { List } from "./styles";

interface UsersListProps {
	content: UserItemProps[];
}

export const UsersList = ({ content }: UsersListProps) => {
	const { pathname, search } = useLocation();

	return (
		<List>
			{content.map(
				({
					id,
					registrationDate,
					fullName,
					avatar,
					phone,
					email,
					color,
					role,
					registryNumber,
					...other
				}) => (
					<ListItem
						key={id}
						url={`${id}`}
						wrapperClass='application-item'
						from={pathname + search}
						content={{
							...other,
							date: registrationDate,
							type: "user",
							content: {
								fullName,
								avatar,
								phone,
								email,
								color,
								role: pathname === "/staff" ? role : undefined,
								registryNumber,
							},
							id,
						}}
					/>
				),
			)}
		</List>
	);
};
