import { ListItemDetail } from "components/ListItemDetail";
import { tabsGenerate } from "utils/helpers";

const CommissionСontrolsDetailPage = () => (
	<ListItemDetail
		type='application'
		url='protocols-control-commission'
		api='commission_controls'
		navigation={tabsGenerate("protocol")}
	/>
);

export default CommissionСontrolsDetailPage;
