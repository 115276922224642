import { useState, useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { Typography } from "@mui/material";
import { baseApiUrl } from "utils/constants";
import { addTitlePage } from "utils/helpers";
import { useRequest } from "utils/hooks";
import { ListStandartProps, RoleItemProps } from "utils/types";
import empty from "assets/images/empty.svg";
import { OrderingIcon } from "assets/icons";
import { Preloader } from "components/Preloader";
import { ErrorResult } from "components/ErrorResult";
import { Pagination } from "components/Pagination";
import { AddButton } from "components/AddButton";
import { Popup } from "components/Popup";
import { Filter } from "components/Filter";
import { NoАccess } from "components/NoАccess";
import { Container as PageContainer } from "components/PageTemplate/styles";
import { ApplicationCreationPopup } from "components/ApplicationCreationPopups";
import {
	Container,
	Empty,
	ImageWrapper,
	Text,
	TopBlock,
	ListWrapper,
	NotFoundWrapper,
	Filters,
} from "./styles";
import { RoleItem } from "components/RoleItem";

export const RolesPage = () => {
	const [list, setList] = useState<RoleItemProps[]>([]);
	const [isError, setIsError] = useState(null);
	const [isNoAccess, setIsNoAccess] = useState(false);
	const [isPreloader, setIsPreloader] = useState(true);
	const [isLoading, setIsLoading] = useState(false);
	const [showPopup, setShowPopup] = useState(false);
	const [orderings, setOrderings] = useState<ListStandartProps[]>([]);
	const [countPages, setCountPages] = useState(0);
	const { search, pathname } = useLocation();
	const request = useRequest;
	const apiUrl = `${baseApiUrl}/admin/users/roles/${search ? search : ""}`;

	useLayoutEffect(() => {
		if (!isLoading) {
			setIsLoading(true);
			addTitlePage(pathname);
			request(apiUrl)
				.then((res) => {
					const {
						data: { results, numPages, pageSize, count, orderings },
						status,
					} = res;
					if (status < 400) {
						if (results && results.length > 0) {
							setList(results);
							if (count > pageSize) setCountPages(numPages);
						}

						if (orderings) setOrderings(orderings);
					} else {
						if (status === 404 || status === 500) {
							setIsError(status);
						} else {
							setIsNoAccess(true);
						}
					}
				})
				.finally(() => {
					setTimeout(() => {
						setIsLoading(false);
						setIsPreloader(false);
					}, 250);
				});
		}
	}, []);

	return (
		<PageContainer>
			{isError || isNoAccess ? (
				<NotFoundWrapper>
					{isError ? (
						<ErrorResult code={isError} url={`/`} />
					) : (
						<NoАccess url={`/`} />
					)}
				</NotFoundWrapper>
			) : (
				<>
					<TopBlock>
						<Typography component='h1' variant='h2'>
							Роли
						</Typography>
					</TopBlock>

					<TopBlock>
						<Filters>
							<TransitionGroup component={null}>
								{orderings.length > 0 && (
									<CSSTransition timeout={250} classNames='fade'>
										<Filter
											id={1}
											field='ordering'
											options={orderings}
											adornment={<OrderingIcon className='adornedStart' />}
										/>
									</CSSTransition>
								)}
							</TransitionGroup>
						</Filters>
					</TopBlock>

					<Container>
						<Preloader active={isLoading || isPreloader}>
							{list.length > 0 ? (
								<ListWrapper>
									{list.map((item) => (
										<RoleItem key={item.id} {...item} />
									))}
									{countPages > 0 && <Pagination count={countPages} />}
								</ListWrapper>
							) : (
								<Empty>
									<ImageWrapper>
										<img src={empty} alt='' />
									</ImageWrapper>
									<Text>Данные отсутсвуют</Text>
								</Empty>
							)}
						</Preloader>
					</Container>
				</>
			)}
		</PageContainer>
	);
};

export default RolesPage;
