import { createSlice } from "@reduxjs/toolkit";
import { PayloadAction } from "@reduxjs/toolkit";
import { ApplicationDetailProps, ApplicationDetailType } from "utils/types";
import { RootState } from "../generateState";

interface ApplicationContentProps {
	applicationContent: ApplicationDetailProps<ApplicationDetailType>;
}

const initialState: ApplicationContentProps = {
	applicationContent: {
		common: null,
		attachmentFiles: [],
		history: [],
		maxFilesSize: undefined,
		table: [],
		relatedApplications: null,
	},
};

const slice = createSlice({
	name: "applicationContent",
	initialState,
	reducers: {
		setApplicationContent: (
			state,
			{ payload }: PayloadAction<ApplicationDetailProps<ApplicationDetailType>>,
		) => {
			console.log("setApplicationContent");
			state.applicationContent = payload;
		},
		setApplicationRegistryNumber: (
			state,
			{ payload }: PayloadAction<string>,
		) => {
			if (state.applicationContent.common !== null) {
				//@ts-ignore
				state.applicationContent.common.registryNumber = payload;
			}
		},
	},
});

export const { setApplicationContent, setApplicationRegistryNumber } =
	slice.actions;

export const selectApplicationContent = (state: RootState) =>
	state.applicationContent;

export const selectApplicationId = (state: RootState) =>
	state.applicationContent.applicationContent.common?.id;

export default slice.reducer;
