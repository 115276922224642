import styled from "styled-components";
import { background } from "styles/colors";
import { mediumDesktop } from "styles/media";

interface ContainerProps {
	auth?: boolean;
}
export const layoutStyles = {
	Content: styled.div`
		min-height: 100vh;
		position: relative;
		${({ auth }: ContainerProps) =>
			auth
				? `display: flex; align-items: center; justify-content: center; padding: 30px 32px;`
				: "padding: 100px 32px 30px;"}
	`,
	Container: styled.div`
		position: relative;
		background-color: ${background};
	`,
	Wrapper: styled.div`
		min-height: 100vh;
		position: relative;

		@media ${mediumDesktop} {
			${({ auth }: ContainerProps) =>
				auth ? "grid-template-columns: 280px auto;" : ""}
		}

		${({ auth }: ContainerProps) =>
			auth ? "display: grid; grid-template-columns: 265px auto;" : ""}
	`,

	HeaderAuth: styled.div`
		display: flex;
		width: 100%;
		position: absolute;

		.MuiBox-root {
			border-bottom: none;
		}
	`,
};
