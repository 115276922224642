import { useUser } from "utils/store";
import { LogoutIcon } from "assets/icons";
import { Member } from "components/Member";
import { Notice } from "components/Notice";
import { ButtonsContainer, Wrapper, Button, Icon } from "./styles";
import { useLogout } from "utils/hooks";

export const Header = () => {
	const user = useUser();

	const { logoutUser } = useLogout();

	return (
		<Wrapper>
			{user ? <Member {...user} /> : null}
			<ButtonsContainer>
				<Notice />
				<Button onClick={logoutUser}>
					<Icon>
						<LogoutIcon />
					</Icon>
				</Button>
			</ButtonsContainer>
		</Wrapper>
	);
};
