import { selectRoleContent, setRoleContent } from "./roleContent";
import {
	selectApplicationContent,
	selectApplicationId,
	setApplicationContent,
	setApplicationRegistryNumber,
} from "./applicationContent";
import { selectMainLoader, setMainLoader } from "./mainLoader";
import {
	selectUser,
	selectUserAvatar,
	selectUserId,
	selectUserRole,
	setUser,
	setUserAvatar,
	setUserName,
} from "./user";
import { setUserDetail, selectUserDetail } from "./userDetail";
import { selectAuthData, setAuthData } from "./authData";

export {
	selectAuthData,
	setAuthData,
	selectUser,
	selectUserAvatar,
	selectUserId,
	selectUserRole,
	setUser,
	setUserAvatar,
	setUserName,
	selectMainLoader,
	setMainLoader,
	selectApplicationContent,
	selectApplicationId,
	setApplicationContent,
	setUserDetail,
	selectUserDetail,
	selectRoleContent,
	setRoleContent,
	setApplicationRegistryNumber,
};
