import { useLayoutEffect } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getUserIdByJwt } from "utils/helpers";
import { useUserUpdate, useLogout } from "utils/hooks";
import { setUser } from "utils/store";

import { basicPaths } from "./routes";
import { useUser } from "utils/store/hooks";

export const Navigation = () => {
	const { pathname } = useLocation();
	const dispatch = useDispatch();
	const user = useUser();
	const userUpdate = useUserUpdate;
	const { logoutUser } = useLogout();

	useLayoutEffect(() => {
		if (!user) {
			console.log("UserIdByJwt", getUserIdByJwt());
			const userId = getUserIdByJwt();
			if (userId !== undefined) {
				userUpdate(getUserIdByJwt()).then((res) => {
					console.log("data", res);
					if (res?.status && res.status >= 200 && res.status < 300) {
						dispatch(setUser({ ...res.data }));
					} else {
						if (pathname !== "/create-password" && pathname !== "/login") {
							logoutUser();
						}
					}
				});
			} else if (pathname !== "/create-password" && pathname !== "/login") {
				logoutUser();
			}
		}
	}, []);

	return (
		<Routes>
			{basicPaths.map(({ id, path, element, children }) =>
				path == "/login" && user ? undefined : children ? (
					<Route key={id} path={path} element={element}>
						<Route index element={children[0].element} />
						{children.map((child) => (
							<Route key={child.id} path={child.path} element={child.element} />
						))}
					</Route>
				) : (
					<Route key={id} path={path} element={element} />
				),
			)}
		</Routes>
	);
};
