import { NavLink } from "./styles";
import type { NavMenuLinkProps } from "utils/types";

export const NavMenuLink = ({ url, title, icon }: NavMenuLinkProps) => {
	const Icon = icon;

	return (
		<NavLink to={url}>
			<Icon />
			{title}
		</NavLink>
	);
};
