import { ApplicationType } from "utils/types";

export const useIsAppType = (type?: ApplicationType) => {
	const isExpertOpinion = type === "expert_opinion";
	const isCommissionProtocol = type === "commission_control";
	const isPresidium = type === "presidium";
	const isVV = type === "vv";
	const isODO = type === "odo";
	const isOO = type === "oo";
	const isPrimary = type === "primary";
	const isAgenda = type === "agenda";
	const isExtract = type === "extract";
	const isTermination = type === "termination";
	const isExtra = type === "extra";
	const isAct = type === "act";

	return {
		isExpertOpinion,
		isCommissionProtocol,
		isPresidium,
		isVV,
		isODO,
		isOO,
		isPrimary,
		isAgenda,
		isTermination,
		isExtract,
		isExtra,
		isAct,
	};
};
