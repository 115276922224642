import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { Typography } from "@mui/material";
import { setApplicationRegistryNumber } from "utils/store";
import { useRequest } from "utils/hooks";
import { baseApiUrl } from "utils/constants";
import { Button, Input } from "components/FormElements";
import { Popup } from "components/Popup";
import { PopupContainer } from "./styles";

interface RegistryPopupProps {
	afterClose: (value: boolean) => void;
	show: boolean;
	id?: number;
}

export const RegistryPopup = ({ afterClose, show, id }: RegistryPopupProps) => {
	const request = useRequest;
	const [isLoading, setIsLoading] = useState(false);
	const [value, setValue] = useState<string>("");
	const { enqueueSnackbar } = useSnackbar();
	const dispatch = useDispatch();

	const clickHandle = () => {
		request(`${baseApiUrl}/users/sro/registry_number/`, {
			method: "PATCH",
			data: { registry_number: value, application_id: id },
		})
			.then((res) => {
				const { data, status } = res;

				if (status >= 200 && status < 300) {
					console.log("setApplicationRegistryNumber");
					dispatch(setApplicationRegistryNumber(value));
					afterClose(false);
					enqueueSnackbar("Реестровый номер успешно становлен!", {
						variant: "success",
					});
				} else {
					const { errors } = data;
					enqueueSnackbar("При отправке запроса возникла ошибка!", {
						variant: "error",
						description: errors[0] ? errors[0].message : `Error ${status}`,
						autoHideDuration: null,
					});
				}
				setTimeout(() => {
					setIsLoading(false);
				}, 250);
			})
			.catch(() => {
				enqueueSnackbar("При отправке запроса возникла ошибка!", {
					variant: "error",
					autoHideDuration: null,
				});
				setTimeout(() => {
					setIsLoading(false);
				}, 250);
			});
	};

	return (
		<Popup active={show} afterClose={afterClose}>
			<PopupContainer
				component='form'
				onSubmit={(e) => {
					e.preventDefault();
					setIsLoading(true);
				}}>
				<Typography component='h2' variant='h2' id='modal-title'>
					Введите реестровый номер
				</Typography>
				<Input
					id={2}
					field=''
					value={value}
					placeholder='Реестровый номер'
					required
					onChange={({ value }) => setValue(`${value}`)}
				/>
				<Button
					type='submit'
					preloader={{ loading: isLoading }}
					disabled={isLoading}
					onClick={clickHandle}>
					Продолжить
				</Button>
			</PopupContainer>
		</Popup>
	);
};

export default RegistryPopup;
