import { NavigationProps, TabsItemProps, NavMenuLinkProps } from "utils/types";

export const tabsGenerate = (type: string) => {
	let titles = [];
	switch (type) {
		case "expertOpinion":
			titles = ["Заключение", "заключении"];
			break;
		case "protocol":
			titles = ["Протокол", "заседании"];
			break;
		case "agenda":
			titles = ["Повестка", "повестке"];
			break;
		default:
			titles = ["", ""];
			break;
	}
	let tabs: TabsItemProps[] = [
		{
			id: 1,
			title: titles[0],
			param: "common",
		},
		{
			id: 2,
			title: `Информация о ${titles[1]}`,
			param: "info",
		},
	];

	return tabs;
};

export const navigationGenerator = (
	navigation: NavigationProps[],
	access: string[],
) => {
	let list: NavMenuLinkProps[] = [];

	navigation.map(({ codename, ...other }) =>
		access.map((item) => {
			if (item === codename) {
				list.push({ ...other });
			}
		}),
	);

	return list;
};
