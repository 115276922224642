import { useState, useEffect } from "react";
import { useLocation, Outlet, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { SwitchTransition, CSSTransition } from "react-transition-group";
import {
	applicationTabs,
	applicationTabs2,
	applicationTabs3,
} from "assets/data";
import { addTitlePage } from "utils/helpers";
import { useRequest } from "utils/hooks";
import {
	TabsItemProps,
	ApplicationDetailType,
	ApplicationDetailProps,
} from "utils/types";
import { setApplicationContent, useApplicationContent } from "utils/store";
import { baseApiUrl } from "utils/constants";
import { Tabs } from "components/Tabs";
import { ErrorResult } from "components/ErrorResult";
import { NoАccess } from "components/NoАccess";
import { ApplicationCardHeader } from "components/ApplicationCard";
import { Preloader } from "components/Preloader";
import { UserItem } from "components/UserItem";
import { HeaderSkeleton } from "./HeaderSkeleton";
import { WhiteLayer } from "styles/common";
import { Сontent, TabsContent, NotFoundWrapper } from "./styles";

interface ListItemDetailProps {
	url: string;
	api: string;
	type: ApplicationDetailType;
	navigation?: TabsItemProps[];
}

const typeUrl = (type: ApplicationDetailType, paths: string[], id?: string) => {
	let piceUrl;

	if (type === "user") {
		piceUrl =
			paths.at(-1) === id || paths.at(-1) === `${id}/`
				? "/related-applications/"
				: `/${paths.at(-1)}/`;
	} else {
		piceUrl =
			paths.at(-1) === "common" ||
			paths.at(-1) === id ||
			paths.at(-1) === `${id}/`
				? "/"
				: `/${paths.at(-1)}/`;
	}

	return piceUrl;
};

export const ListItemDetail = ({
	type,
	url,
	api,
	navigation,
}: ListItemDetailProps) => {
	const [isLoading, setIsLoading] = useState(false);
	const [isMainLoading, setIsMainLoading] = useState(true);
	const [isError, setIsError] = useState(null);
	const [isNoAccess, setIsNoAccess] = useState(false);
	const dispatch = useDispatch();
	const content = useApplicationContent();
	const request = useRequest;
	const navigate = navigation
		? navigation
		: // @ts-ignore
		content.common?.type === "primary"
		? applicationTabs // @ts-ignore
		: content.common?.type === "extra"
		? applicationTabs3
		: applicationTabs2;
	const [activeTab, setActiveTab] = useState(navigate[0].param);
	const { pathname } = useLocation();
	const splitedPath = pathname.split("/").filter((item) => item !== "");
	const { id } = useParams();
	const apiUrl = `${baseApiUrl}/${api}/${id}${typeUrl(type, splitedPath, id)}`;
	const title = type === "user" ? "Пользователь" : "Заявка";

	const sendRequest = () => {
		console.log(apiUrl);
		request(apiUrl)
			.then((res) => {
				const { data, status } = res;
				if (status < 400) {
					const newContent: ApplicationDetailProps<ApplicationDetailType> = {
						...content,
						...data,
					};
					addTitlePage(
						pathname,
						type === "user" ? data.common.fullName : data.common.name,
					);
					dispatch(setApplicationContent(newContent));
				} else {
					if (status === 404 || status === 500) {
						setIsError(status);
					} else {
						setIsNoAccess(true);
					}
				}
			})
			.finally(() => {
				setTimeout(() => {
					setIsLoading(false);
					setIsMainLoading(false);
				}, 250);
			});
	};

	useEffect(() => {
		console.log("activeTab", activeTab);
		if (!isLoading) {
			setIsLoading(true);
			sendRequest();
		}
	}, [activeTab]);

	return (
		<WhiteLayer>
			{isError || isNoAccess ? (
				<NotFoundWrapper>
					{isError ? (
						<ErrorResult
							code={isError}
							url={`/${url}`}
							title={
								isError === 404
									? `${title} №${id} не найден${
											type === "application" ? "а" : ""
									  }!`
									: "Сервер не отвечает!"
							}
							buttonName={`К списку ${
								type === "user" ? "пользователей" : "заявок"
							}`}
						/>
					) : (
						<NoАccess url={`/`} />
					)}
				</NotFoundWrapper>
			) : (
				<Сontent>
					<SwitchTransition mode='out-in'>
						<CSSTransition
							key={content.common ? "content" : "skeleton"}
							timeout={300}
							classNames='fade'>
							{content.common && !isMainLoading ? (
								<>
									{type === "user" ? (
										// @ts-ignore
										<UserItem
											{...content.common}
											status={content.common.status}
											backUrl={`/${url}`}
											noPadding
											isHeaderDetail
										/>
									) : (
										// @ts-ignore
										<ApplicationCardHeader
											{...content.common}
											url={url}
											api={api}
										/>
									)}
								</>
							) : (
								<HeaderSkeleton type={type} />
							)}
						</CSSTransition>
					</SwitchTransition>
					<Tabs
						tabs={navigate}
						commonPath={`/${url}/${id}`}
						changeParamUrl={setActiveTab}>
						<TabsContent>
							<Preloader active={isLoading}>
								<SwitchTransition>
									<CSSTransition
										key={activeTab}
										classNames='fadeIn'
										timeout={250}>
										<Outlet />
									</CSSTransition>
								</SwitchTransition>
							</Preloader>
						</TabsContent>
					</Tabs>
				</Сontent>
			)}
		</WhiteLayer>
	);
};

export default ListItemDetail;
