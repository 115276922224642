import { ListLayout } from "components/ListLayout";

const CandidatesPage = () => {
	return (
		<ListLayout
			title='Кандидаты в члены СРО'
			url='candidates'
			api='users/sro/candidates'
			type='users'
			searchFilter='Поиск по ИНН'
		/>
	);
};

export default CandidatesPage;
