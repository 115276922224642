import { ListItemCommonProps, ListItemUserContentProps } from "utils/types";
import { Status } from "components/Status";
import { Avatar } from "components/Avatar";
import { Chip } from "components/Chip";
import {
	Container,
	LeftBlock,
	CenterBlock,
	RightBlock,
	AvatarWrapper,
	Text,
	TextSecondary,
	TextSecondarySmall,
	DescriptionBlock,
	RolesBlock,
} from "./styles";

interface UserItemInfoProps
	extends ListItemCommonProps,
		ListItemUserContentProps {}

export const UserItemInfo = ({
	date,
	status,
	id,
	fullName,
	email,
	phone,
	avatar,
	color,
	role,
	registryNumber,
}: UserItemInfoProps) => {
	const dateInFormat = new Date(date).toLocaleDateString();

	return (
		<Container>
			<LeftBlock>
				<Text>{registryNumber ? `№${registryNumber}` : `ID: ${id}`}</Text>
				<TextSecondarySmall>Зарегистрирован: {dateInFormat}</TextSecondarySmall>
			</LeftBlock>
			<CenterBlock>
				<AvatarWrapper>
					<Avatar image={avatar} name={fullName} color={color}></Avatar>
				</AvatarWrapper>
				<LeftBlock>
					<Text>{fullName}</Text>

					{role ? (
						<RolesBlock>
							{role.map(({ id, name }) => (
								<Chip key={id} label={name} small />
							))}
						</RolesBlock>
					) : (
						<DescriptionBlock>
							<TextSecondary>{phone}</TextSecondary>
							<TextSecondary>{email}</TextSecondary>
						</DescriptionBlock>
					)}
				</LeftBlock>
			</CenterBlock>
			<RightBlock>
				<Status status={status} type='standart' rounded />
			</RightBlock>
		</Container>
	);
};
