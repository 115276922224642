import { styled } from "@mui/material/styles";
import {
	Box,
	Accordion as AccordionMui,
	AccordionSummary as AccordionSummaryMui,
	AccordionDetails as AccordionDetailsMui,
	RadioGroup,
	FormControlLabel,
	Radio as MuiRadio,
	Typography,
} from "@mui/material";
import { liteGrey, blue, black, red, grey, line } from "styles/colors";
import { robotoMedium } from "styles/fonts";
import { rgba } from "styles/mixins";

export const Сontent = styled(Box)`
	width: 100%;
	min-height: calc(100vh - 130px - 60px);
`;

export const PreloaderWr = styled(Box)`
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 100%;
`;

export const Header = styled(Box)`
	display: flex;
	align-items: center;
	gap: 24px;
	margin-bottom: 32px;
`;

export const InputWrapper = styled(Box)`
	position: relative;
	width: 400px;
`;

export const CheckedList = styled(Box)`
	display: flex;
	align-items: center;
	gap: 8px;
	margin: 32px 0;
`;

export const EmptyList = styled(Box)`
	color: ${liteGrey};
	font-size: 14px;
	text-align: center;
`;

export const Permissions = styled(RadioGroup)`
	display: flex;
	flex-direction: column;
	gap: 0;
	padding-top: 32px;
`;

export const Accordion = styled(AccordionMui)`
	box-shadow: none;
	position: relative;
	margin-top: -1px;

	&::before {
		content: none;
	}

	&.Mui-expanded {
		margin: -1px;
	}
`;

export const AccordionSummary = styled(AccordionSummaryMui)`
	padding: 0;
	position: static;

	.MuiAccordionSummary-content {
		margin: 0;

		&.Mui-expanded {
			margin: 0;
		}
	}
`;

export const AccordionDetails = styled(AccordionDetailsMui)`
	padding: 0 0 20px;
	position: relative;
	z-index: 10;
`;

export const CheckboxList = styled(Box)`
	display: flex;
	flex-direction: column;
	gap: 8px;
	transform: translateX(2px);
`;

export const ApplicationItem = styled(FormControlLabel)`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 20px 0;
	text-align: left;
	margin: 0;
`;

export const Radio = styled(MuiRadio)`
	padding: 0;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border: 1px solid ${line};
	border-radius: 0;
	border-left-color: transparent;
	border-right-color: transparent;
	transition: all ease-in-out 0.25s;
`;

export const Label = styled(Box)`
	font-size: 15px;
	line-height: 22px;
	color: ${black};
	margin-bottom: 8px;

	&::after {
		content: "*";
		display: "inline-block";
		color: ${red};
		margin-left: 3px;
	}
`;

export const ButtonWrapper = styled(Box)`
	display: flex;
	align-items: center;
	gap: 8px;
	margin-top: 32px;
`;

export const Container = styled(Box)`
	width: 100%;
	height: 100%;
`;

export const Empty = styled(Box)`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
`;

export const ImageWrapper = styled(Box)`
	width: 292px;
	height: 292px;
	position: relative;

	img {
		width: 100%;
		height: 100%;
		object-fit: contain;
	}
`;

export const Text = styled(Typography)`
	font-family: ${robotoMedium};
	font-size: 18px;
	line-height: 130%;
	text-align: center;
	color: ${liteGrey};
	margin: 8px 0 32px;
`;

export const NotFoundWrapper = styled(Box)`
	display: flex;
	align-items: center;
	min-height: calc(100vh - 130px - 60px);
`;

export const TopBlock = styled(Box)`
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 40px;
`;

export const ListWrapper = styled(Box)`
	display: flex;
	flex-direction: column;

	.role-item {
		border-bottom: 1px solid ${line};

		.role-item-wr {
			border-radius: 0;
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 20px 20px 19px;

			svg {
				stroke: ${blue};
			}
		}

		&:first-of-type {
			.role-item-wr {
				border-radius: 10px 10px 0 0;
			}
		}

		&:last-of-type {
			border-bottom: none;

			.role-item-wr {
				border-radius: 0 0 10px 10px;
			}
		}
	}
`;

export const Filters = styled(Box)`
	display: flex;
	align-items: center;
	gap: 16px;
`;
