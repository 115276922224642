import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
	setUser,
	useUser,
	setApplicationContent,
	setUserDetail,
} from "utils/store";
import { clearLocalStorage } from "utils/helpers";
import { LogoutIcon } from "assets/icons";
import { Member } from "components/Member";
import { Notice } from "components/Notice";
import { ButtonsContainer, Wrapper, Button, Icon } from "./styles";

export const Header = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const user = useUser();

	const logoutUser = () => {
		clearLocalStorage();
		dispatch(setUser(null));
		dispatch(
			setApplicationContent({
				common: null,
				attachmentFiles: [],
				history: [],
				maxFilesSize: undefined,
				table: [],
				relatedApplications: null,
			}),
		);
		dispatch(setUserDetail(null));
		console.log("remove redirect from header by logout in future");
		navigate("/login");
	};

	return (
		<Wrapper>
			{user ? <Member {...user} /> : null}
			<ButtonsContainer>
				<Notice />
				<Button onClick={logoutUser}>
					<Icon>
						<LogoutIcon />
					</Icon>
				</Button>
			</ButtonsContainer>
		</Wrapper>
	);
};
