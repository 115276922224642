// @ts-nocheck
import { useState, useLayoutEffect, useEffect, useRef } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { Typography } from "@mui/material";
import { baseApiUrl } from "utils/constants";
import { addTitlePage } from "utils/helpers";
import { useRequest, useIsRole } from "utils/hooks";
import {
	ApplicationProps,
	UserItemProps,
	ListStandartProps,
} from "utils/types";
import empty from "assets/images/empty.svg";
import { useApplicationContent } from "utils/store";
import { OrderingIcon } from "assets/icons";
import { Button } from "components/FormElements/Button";
import { ApplicationsList } from "components/ApplicationsList";
import { UsersList } from "components/UsersList";
import { LinkWrapper } from "components/Wrappers";
import { Preloader } from "components/Preloader";
import { ErrorResult } from "components/ErrorResult";
import { Pagination } from "components/Pagination";
import { AddButton } from "components/AddButton";
import { Popup } from "components/Popup";
import { ProtocolsPopup } from "components/ProtocolsPopup";
import { Filter } from "components/Filter";
import { NoАccess } from "components/NoАccess";
import { AddEmployee } from "components/AddEmployee";
import { Container as PageContainer } from "components/PageTemplate/styles";
import { ApplicationCreationPopup } from "components/ApplicationCreationPopups";
import {
	Container,
	Content,
	Empty,
	ImageWrapper,
	Text,
	TopBlock,
	ListWrapper,
	NotFoundWrapper,
	Filters,
} from "./styles";
import { Search } from "components/FormElements";

interface ListLayoutProps {
	title?: string;
	url: string;
	api: string;
	type: "users" | "applications";
	detail?: boolean;
	searchFilter?: string;
}

interface ListProps {
	applications: ApplicationProps[];
	users: UserItemProps[];
}

interface FilterProps {
	name: string;
	value: string;
	options: ListStandartProps[];
}

export const ListLayout = ({
	title,
	url,
	api,
	type,
	detail,
	searchFilter,
}: ListLayoutProps) => {
	const [list, setList] = useState<ListProps[typeof type]>([]);
	const [isError, setIsError] = useState(null);
	const [isNoAccess, setIsNoAccess] = useState(false);
	const [isPreloader, setIsPreloader] = useState(true);
	const [isLoading, setIsLoading] = useState(false);
	const [isButtonLoading, setIsButtonLoading] = useState(false);
	const [showPopup, setShowPopup] = useState(false);
	const [filters, setFilters] = useState<FilterProps[]>([]);
	const [orderings, setOrderings] = useState<ListStandartProps[]>([]);
	const [countPages, setCountPages] = useState(0);
	const { search, pathname } = useLocation();
	const {
		isHeadExpert,
		isEmployee,
		isAdmin,
		isMember,
		isMonitoringSpecialist,
		isClerkExpert,
		isLegalAdvisor,
		isCandidate,
	} = useIsRole();
	const navigate = useNavigate();
	const request = useRequest;
	const isPresidium = url === "protocols-presidium";
	const isCommissionControl = url === "protocols-control-commission";
	const splitedPath = pathname.split("/").filter((item) => item !== "");
	const { id } = useParams();
	const piceUrl = detail
		? splitedPath.at(-1) === id
			? `/${id}/related-applications`
			: `/${id}/${splitedPath.at(-1)}`
		: "";
	const apiUrl = `${baseApiUrl}/${api}${piceUrl}/${search ? search : ""}`;
	const content = useApplicationContent();

	useLayoutEffect(() => {
		if (!isLoading) {
			addTitlePage(pathname);

			if (
				(!detail && splitedPath.at(-1) !== id) ||
				search.length > 0 ||
				(detail && !content?.results && search.length === 0 && id)
			) {
				setIsLoading(true);
				request(apiUrl)
					.then((res) => {
						const {
							data: { results, numPages, pageSize, count, filters, orderings },
							status,
						} = res;
						if (status < 400) {
							if (results) {
								setList(results);
								setCountPages(count > pageSize ? numPages : 0);
							}
							if (filters) setFilters(filters);
							if (orderings) setOrderings(orderings);
						} else {
							if (status === 404 || status === 500) {
								setIsError(status);
							} else {
								setIsNoAccess(true);
							}
						}
					})
					.finally(() => {
						setTimeout(() => {
							setIsLoading(false);
							setIsPreloader(false);
						}, 250);
					});
			}
		}
	}, [search]);

	useEffect(() => {
		if (
			(detail && splitedPath.at(-1) === id) ||
			(id &&
				splitedPath.at(-1) === "related-applications" &&
				content?.results &&
				search.length == 0)
		) {
			if (content.results && content.results.length > 0) {
				setList(content.results);
				if (content.count > content.pageSize) setCountPages(content.numPages);
			}
			if (content.filters) setFilters(content.filters);
			if (content.orderings) setOrderings(content.orderings);

			setTimeout(() => {
				setIsPreloader(false);
			}, 250);
		}
	}, [content]);

	const createPresidium = (type: "presidium" | "commission_controls") => {
		setIsButtonLoading(true);
		const isPresidiumType = type === "presidium";
		const params = {
			method: "POST",
			data: {
				[isPresidiumType ? type : "commissionControl"]: {
					[isPresidiumType ? "agenda_items" : "applications"]: [],
				},
			},
		};
		request(`${baseApiUrl}/${type}/`, params).then((res) => {
			const { data } = res;
			setIsButtonLoading(false);
			navigate(`/${url}/${data.id}`);
		});
	};

	const createTermination = () => {};

	return (
		<PageContainer>
			{isError || isNoAccess ? (
				<NotFoundWrapper>
					{isError ? (
						<ErrorResult
							code={isError}
							url={`/${url}`}
							title={`Страница не найдена!`}
							buttonName={`К списку ${
								type === "users" ? "пользователей" : "заявок"
							}`}
						/>
					) : (
						<NoАccess url={`/`} />
					)}
				</NotFoundWrapper>
			) : (
				<>
					{title && (
						<TopBlock>
							<Typography component='h1' variant='h2'>
								{url === "applications" && !isEmployee ? "Мои заявки" : title}
							</Typography>

							{isMember && (
								<>
									<AddButton
										title='Создать заявку'
										onClick={() => setShowPopup(true)}
									/>
									<Popup active={showPopup} afterClose={setShowPopup}>
										<ApplicationCreationPopup />
									</Popup>
								</>
							)}

							{(isMonitoringSpecialist || isClerkExpert || isLegalAdvisor) && (
								<>
									<Button
										variant='contained'
										onClick={() => setShowPopup(true)}>
										Cоздать заявку на прекращение членства
									</Button>
									<Popup active={showPopup} afterClose={setShowPopup}>
										<ProtocolsPopup
											title='Cоздать заявку на прекращение членства'
											afterClose={setShowPopup}
											url='users/sro/members/application'
											listType='radio'
											buttonName='Создать заявку'
											search
											isMembers
											method='POST'
										/>
									</Popup>
								</>
							)}

							{(isPresidium || isCommissionControl) && isHeadExpert && (
								<AddButton
									title='Создать протокол'
									onClick={() =>
										createPresidium(
											isPresidium ? "presidium" : "commission_controls",
										)
									}
									disabled={isButtonLoading}
								/>
							)}

							{isAdmin && url === "staff" && <AddEmployee />}
						</TopBlock>
					)}
					{(isEmployee || isMember) && (
						<TopBlock>
							<Filters>
								<TransitionGroup component={null}>
									{filters.map(({ value, ...other }, index) => (
										<CSSTransition key={value} timeout={250} classNames='fade'>
											<Filter id={index} field={value} {...other} />
										</CSSTransition>
									))}
									{searchFilter && (
										<Search placeholder={searchFilter} withParam />
									)}
								</TransitionGroup>
							</Filters>

							<Filters>
								<TransitionGroup component={null}>
									{orderings.length > 0 && (
										<CSSTransition timeout={250} classNames='fade'>
											<Filter
												id={1}
												field='ordering'
												options={orderings}
												adornment={<OrderingIcon className='adornedStart' />}
											/>
										</CSSTransition>
									)}
								</TransitionGroup>
							</Filters>
						</TopBlock>
					)}
					<Content detail={`${detail}`}>
						<Container>
							<Preloader active={isLoading || isPreloader}>
								{list.length > 0 ? (
									<ListWrapper className={detail ? "detail" : undefined}>
										{type === "users" ? (
											// @ts-ignore
											<UsersList content={list} />
										) : (
											// @ts-ignore
											<ApplicationsList content={list} detail={detail} />
										)}
										{countPages > 0 && <Pagination count={countPages} />}
									</ListWrapper>
								) : (
									<Empty>
										<ImageWrapper>
											<img src={empty} alt='' />
										</ImageWrapper>
										<Text>
											{isEmployee
												? "Данные отсутсвуют"
												: "У вас пока что нет заявок"}
										</Text>
										{isCandidate && (
											<LinkWrapper url='/applications/creating'>
												<Button variant='contained'>
													Подать заявку на вступление в СРО
												</Button>
											</LinkWrapper>
										)}
									</Empty>
								)}
							</Preloader>
						</Container>
					</Content>
				</>
			)}
		</PageContainer>
	);
};

export default ListLayout;
