import { ListLayout } from "components/ListLayout";

import { useIsRole } from "utils/hooks";

export const RelatedApplicationsTab = ({ url }: { url: string }) => {
	const { isAdmin } = useIsRole();

	return (
		<ListLayout
			type='applications'
			url={isAdmin ? "applications" : url}
			api='users/sro'
			detail={true}
		/>
	);
};
