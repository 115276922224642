import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";
import { baseApiUrl } from "utils/constants";
import {
	InputOnChange,
	InputDataProps,
	FormSectionDataProps,
} from "utils/types";
import {
	addedPureErrorOnSections,
	hideErrorOnSections,
	getFormPureValues,
} from "utils/helpers";
import { Button } from "components/FormElements";
import { Form } from "components/Form";
import { FormSection } from "components/FormSection";
import { additionalApp } from "assets/data/applicationForm";
import { PopupContainer, AdditionalForm, ButtonContainer } from "./styles";

interface AdditionalApplicationProps {
	applicationId?: number;
}

export const AdditionalApplication = ({
	applicationId,
}: AdditionalApplicationProps) => {
	const [sectionsProps, setSectionsProps] =
		useState<FormSectionDataProps[]>(additionalApp);
	const [formValues, setFormValues] = useState<any>();
	const [preloader, setPreloader] = useState(false);
	const url = `${baseApiUrl}/applications/extra/`;
	const navigate = useNavigate();

	useEffect(() => {
		setFormValues({
			...getFormPureValues(sectionsProps),
			application: applicationId,
		});
	}, [sectionsProps]);

	const submit = (data: any) => {
		let newSections = [];
		if (data.errors) {
			newSections = addedPureErrorOnSections(data.errors, sectionsProps);
		} else {
			newSections = hideErrorOnSections(sectionsProps);
			navigate(`/extra/${data?.common?.id || data?.id}`);
		}
		setSectionsProps(newSections);
		setPreloader(false);
	};

	const changeValue: InputOnChange = ({ field, value, id }) => {
		let newInputsProps: InputDataProps[] = [];
		let newSectionsProps: FormSectionDataProps[] = [];

		sectionsProps.map((section) => {
			let newInputs: InputDataProps[] = [];

			const inputsList = section.inputs;

			inputsList.map((item) => {
				let newItem = item;

				if (field === item.field && id === `${item.field}-${item.id}`) {
					newItem = { ...item, value: value, invalid: false };
				}

				newInputs.push(newItem);
				newInputsProps.push(newItem);
			});

			newSectionsProps.push({ ...section, inputs: newInputs });
		});

		setSectionsProps(newSectionsProps);
	};

	return (
		<PopupContainer>
			<Typography component='h2' variant='h2' id='modal-title'>
				Создание заявки о вступлении в силу решения о приеме в члены СРО
			</Typography>
			<Form
				url={url}
				method='POST'
				values={formValues}
				afterSubmit={submit}
				beforeSubmit={() => setPreloader(true)}
				button={
					<ButtonContainer>
						<Button
							type='submit'
							preloader={{ loading: preloader }}
							disabled={preloader}>
							Создать заявку
						</Button>
					</ButtonContainer>
				}>
				<AdditionalForm>
					{sectionsProps.map(({ id, ...other }, index) => (
						<FormSection
							key={id}
							id={id}
							headlineSize='h6'
							className='additional'
							index={index}
							{...other}
							onChange={changeValue}
						/>
					))}
				</AdditionalForm>
			</Form>
		</PopupContainer>
	);
};

export default AdditionalApplication;
