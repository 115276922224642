import { ListItemDetail } from "components/ListItemDetail";
import { tabsGenerate } from "utils/helpers";

const AgendaDetailPage = () => (
	<ListItemDetail
		type='application'
		url='questions-agenda'
		api='agenda'
		navigation={tabsGenerate("agenda")}
	/>
);

export default AgendaDetailPage;
