import ApplicationsPage from "./applications";
import ApplicationCreatingPage from "./applicationCreating";
import ApplicationDetailPage from "./applicationDetail";
import ApplicationExtraDetailPage from "./applicationExtraDetail";

export {
	ApplicationsPage,
	ApplicationCreatingPage,
	ApplicationDetailPage,
	ApplicationExtraDetailPage,
};
