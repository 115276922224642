import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import { robotoMedium } from "styles/fonts";
import { white, red, blue, liteGrey, background } from "styles/colors";

export const Container = styled(Box)<{ result: boolean }>`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
	padding: 20px;
	gap: 12px;
	border-radius: 8px;
	border: 1px solid ${({ result }) => (result ? blue : red)};
	color: ${({ result }) => (result ? blue : red)};

	svg {
		stroke: ${white};
	}
`;

export const IconWrapper = styled(Box)<{ result: boolean }>`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 24px;
	height: 24px;
	background-color: ${({ result }) => (result ? blue : red)};
	border-radius: 50%;
`;

export const Text = styled(Box)`
	font-family: ${robotoMedium};
	font-size: 18px;
	line-height: 23px;
`;
