import { TabsItemProps } from "utils/types";

export const applicationTabs: TabsItemProps[] = [
	{
		id: 1,
		title: "Заявка",
		param: "common",
	},
	{
		id: 2,
		title: "Информация о кандидате",
		param: "author",
	},
];

export const applicationTabs2: TabsItemProps[] = [
	{
		id: 1,
		title: "Заявка",
		param: "common",
	},
	{
		id: 2,
		title: "Информация о заявке",
		param: "detail",
	},
	{
		id: 3,
		title: "Информация о члене СРО",
		param: "author",
	},
];

export const applicationTabs3: TabsItemProps[] = [
	{
		id: 1,
		title: "Заявка",
		param: "common",
	},
	{
		id: 2,
		title: "Информация о заявке",
		param: "detail",
	},
	{
		id: 3,
		title: "Информация о кандидате",
		param: "author",
	},
];

export const userTabs: TabsItemProps[] = [
	{
		id: 1,
		title: "Заявки",
		param: "related-applications",
	},
	{
		id: 2,
		title: "Информация",
		param: "full-info",
	},
];
