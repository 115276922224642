import { useState, useEffect, SetStateAction, Dispatch } from "react";
import { Typography } from "@mui/material";
import { Button, DatePicker } from "components/FormElements";
import { Popup } from "components/Popup";
import { PopupContainer } from "./styles";

interface DatePopupProps {
	title: string;
	button: string;
	afterClose: (value: boolean) => void;
	show: boolean;
	date: string;
	setDate: Dispatch<SetStateAction<string>>;
	onSubmit: () => void;
	hasTime?: boolean;
	withTimezome?: boolean;
}

export const DatePopup = ({
	title,
	afterClose,
	button,
	show,
	date,
	setDate,
	onSubmit,
	hasTime,
	withTimezome,
}: DatePopupProps) => {
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		if (!show) {
			setIsLoading(false);
		}
	}, [show]);

	return (
		<Popup active={show} afterClose={afterClose}>
			<PopupContainer
				component='form'
				onSubmit={(e) => {
					e.preventDefault();
					setIsLoading(true);
					onSubmit();
				}}>
				<Typography component='h2' variant='h2' id='modal-title'>
					{title}
				</Typography>
				<DatePicker
					id={1}
					value={date}
					field=''
					placeholder={`Введите или выберете дату${hasTime ? " и время" : ""}`}
					onChange={({ value }) => setDate(`${value}`)}
					dateProps={{ disablePast: true }}
					hasTime={hasTime}
					required
					withTimezome={withTimezome}
				/>
				<Button
					type='submit'
					preloader={{ loading: isLoading }}
					disabled={date.length == 0 || isLoading}>
					{button}
				</Button>
			</PopupContainer>
		</Popup>
	);
};

export default DatePopup;
