import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setUser, setApplicationContent, setUserDetail } from "utils/store";
import { clearLocalStorage } from "utils/helpers";

export const useLogout = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const logoutUser = () => {
		clearLocalStorage();
		dispatch(setUser(null));
		dispatch(
			setApplicationContent({
				common: null,
				attachmentFiles: [],
				history: [],
				maxFilesSize: undefined,
				table: [],
				relatedApplications: null,
			}),
		);
		dispatch(setUserDetail(null));
		console.log("remove redirect from header by logout in future");
		navigate("/login");
	};

	return { logoutUser };
};
