import {
	applicationTabs,
	applicationTabs2,
	applicationTabs3,
	userTabs,
} from "./tabs";
import {
	applicationsTypes,
	odoLevel,
	extractInputs,
	odoLevelExtraParam,
} from "./applicationsTypes";
import { addEmployeeForm } from "./addEmployee";
import { titles } from "./titles";
import { applicationForm } from "assets/data/applicationForm";
import {
	profileInfo,
	profileMenu,
	detailMenu,
	adminMenu,
	profilePassword,
	profileAddresses,
	profileCompany,
	employeeInfo,
} from "assets/data/profile";
import { navigation, navigation2 } from "assets/data/navigation";

export {
	navigation,
	navigation2,
	applicationForm,
	profileMenu,
	detailMenu,
	adminMenu,
	profileInfo,
	profilePassword,
	profileAddresses,
	profileCompany,
	applicationsTypes,
	applicationTabs,
	applicationTabs2,
	applicationTabs3,
	userTabs,
	addEmployeeForm,
	employeeInfo,
	titles,
	odoLevel,
	extractInputs,
	odoLevelExtraParam,
};
