import { ListItemDetail } from "components/ListItemDetail";
import { tabsGenerate } from "utils/helpers";

const ExpertOpinionsDetailPage = () => (
	<ListItemDetail
		type='application'
		url='expert-opinions'
		api='expert_opinions'
		navigation={tabsGenerate("expertOpinion")}
	/>
);

export default ExpertOpinionsDetailPage;
