import { useLocation } from "react-router-dom";
import { Typography, Skeleton } from "@mui/material";
import { UserItemProfileProps } from "utils/types";
import { Status } from "components/Status";
import { BackIconButton } from "components/BackIconButton";
import { AvatarUploader, Avatar } from "components/Avatar";
import { Chip } from "components/Chip";
import {
	Container,
	LeftBlock,
	RightBlock,
	AvatarWrapper,
	ColBlock,
	RolesBlock,
} from "./styles";

interface UserItemProps extends UserItemProfileProps {
	backUrl?: string;
	uploader?: boolean;
	noPadding?: boolean;
	showRole?: boolean;
	isHeaderDetail?: boolean;
}

export const UserItem = ({
	backUrl,
	status,
	fullName,
	id,
	registrationDate,
	avatar,
	uploader,
	color,
	role,
	noPadding,
	showRole = true,
	registryNumber,
}: UserItemProps) => {
	const { state } = useLocation();
	const backLink = state?.from ? state.from : backUrl;
	const date = new Date(registrationDate).toLocaleDateString();

	return (
		<Container nopadding={`${noPadding}`}>
			<LeftBlock>
				{backUrl && <BackIconButton link={backLink} />}
				<AvatarWrapper>
					{uploader ? (
						<AvatarUploader id={id} image={avatar} profile={!backUrl} />
					) : (
						<Avatar image={avatar} name={fullName} color={color} />
					)}
				</AvatarWrapper>
				<ColBlock>
					<LeftBlock>
						<Typography component='h3' variant='h3'>
							{fullName}
						</Typography>
						<Status status={status} type='standart' rounded />
					</LeftBlock>
					{role && showRole && (
						<RolesBlock>
							{role.map(({ id, name }) => (
								<Chip key={id} label={name} />
							))}
						</RolesBlock>
					)}
				</ColBlock>
			</LeftBlock>
			<RightBlock>
				<Chip label={`ID: ${id}`} />
				{registryNumber && <Chip label={`№ ${registryNumber}`} />}
				<Chip label={`Зарегистрирован: ${date}`} />
			</RightBlock>
		</Container>
	);
};
