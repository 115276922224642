import { useState, useLayoutEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { SwitchTransition, CSSTransition } from "react-transition-group";
import { useUser } from "utils/store/hooks";
import { Header } from "components/Header";
import { NavMenu } from "components/NavMenu";
import { Footer } from "components/Footer";
import { Logotype } from "components/Logotype";
import { Navigation } from "utils/router/Navigation";
import { layoutStyles } from "./styles";

const { Container, Wrapper, HeaderAuth, Content } = layoutStyles;

export const Layout = () => {
	const isAuth = useUser();
	const [noTransition, setNoTransition] = useState(false);
	const { key, hash, pathname } = useLocation();
	const params = useParams();

	useLayoutEffect(() => {
		const lastItem = pathname.split("/").at(-1);
		let transition =
			lastItem === "common" ||
			lastItem === "author" ||
			lastItem === "detail" ||
			lastItem === "related-applications" ||
			lastItem === "full-info" ||
			lastItem === "info" ||
			lastItem === "addresses" ||
			lastItem === "company" ||
			lastItem === "password";
		setNoTransition(hash.length > 0 || transition || params.id !== undefined);
	}, [params]);

	return (
		<Wrapper auth={isAuth !== null}>
			{isAuth && <NavMenu />}
			<Container>
				{isAuth ? (
					<Header />
				) : (
					<HeaderAuth>
						<Logotype />
					</HeaderAuth>
				)}
				<Content auth={!isAuth}>
					{/* <SwitchTransition>
						<CSSTransition
							key={key}
							classNames={noTransition ? "" : "fadeIn"}
							timeout={noTransition ? 0 : 250}> */}
					<Navigation />
					{/* </CSSTransition>
					</SwitchTransition> */}
				</Content>
				{!isAuth && <Footer />}
			</Container>
		</Wrapper>
	);
};
