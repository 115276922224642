import { ApplicationItemInfoProps } from "utils/types";
import { ClockRoundIcon, PersonRoundIcon, CalendarIcon } from "assets/icons";
import { Status } from "components/Status";
import { Chip } from "components/Chip";
import { convertDateToFormat, convertTimeToFormat } from "utils/helpers";
import { useIsAppType } from "utils/hooks";
import {
	Container,
	AlignCenterBlock,
	Title,
	Finalize,
	Created,
} from "./styles";

interface ApplicationItemInfoComponentProps extends ApplicationItemInfoProps {
	titleSize?: "h2" | "h4";
	size?: "small";
	finalizePeriod?: string | null;
	light?: boolean;
}

export const ApplicationItemInfo = ({
	date,
	status,
	id,
	name,
	creator,
	titleSize,
	finalizePeriod,
	size,
	light,
	outdated,
	type,
	subType,
}: ApplicationItemInfoComponentProps) => {
	const titleProps = titleSize ? titleSize : size ? "h5" : "h4";
	const dateInFormat = [convertDateToFormat(date), convertTimeToFormat(date)];
	const finalizeDate = finalizePeriod
		? convertDateToFormat(finalizePeriod)
		: null;
	const chipProps = { small: size ? true : undefined };
	const { isTermination } = useIsAppType(type);

	return (
		<Container className={size ? size : ""}>
			<AlignCenterBlock>
				<Title
					// @ts-ignore
					component={titleProps}
					variant={titleProps}
					className='list-item-content'>
					{name}
				</Title>
				<Status status={status} type='application' rounded />
				{finalizeDate && (
					<Finalize>
						<CalendarIcon />
						{isTermination
							? subType === "Выход за неуплату"
								? "Погасить задолженность до"
								: "Устранить нарушения до"
							: "Срок доработки"}
						: {finalizeDate}
					</Finalize>
				)}
				{outdated && (
					<Status
						status={{ name: "Просрочено", type: "outdated" }}
						type='application'
						rounded
					/>
				)}
			</AlignCenterBlock>
			<AlignCenterBlock>
				{light ? (
					<Created>{`Создана: ${dateInFormat[0]} ${dateInFormat[1]}`}</Created>
				) : (
					<>
						<Chip label={`№${id}`} {...chipProps} />
						<Chip
							icon={<ClockRoundIcon />}
							label={`${dateInFormat[0]} ${dateInFormat[1]}`}
							{...chipProps}
						/>
						<Chip icon={<PersonRoundIcon />} label={creator} {...chipProps} />
					</>
				)}
			</AlignCenterBlock>
		</Container>
	);
};
