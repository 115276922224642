import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import { Layout } from "components/Layout";
import { GlobalStyle } from "styles/global";
import { theme } from "styles/theme";

const App = () => {
	return (
		<BrowserRouter>
			<GlobalStyle />
			<ThemeProvider theme={theme}>
				<Layout />
			</ThemeProvider>
		</BrowserRouter>
	);
};

export default App;
