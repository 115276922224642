export const baseApiUrl = "https://sro.itfactory.site/api/v1";
export const baseWSSUrl = "wss://sro.itfactory.site/ws";
export const dadataApiUrl =
	"https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/party";
export const dadataToken = "f956c81386d9a18cad92709c89699cbfedfff2bb";

export const candidateRoleId = 1;
export const memberRoleId = 2;
export const managerRoleId = 3;
export const headExpertRoleId = 4;
export const directorRoleId = 5;
export const commisionRoleId = 6;
export const presidiumRoleId = 8;
export const headLegalRoleId = 9;
export const headAccountantRoleId = 10;
export const monitoringSpecialistRoleId = 15;
export const clerkExpertRoleId = 16;
export const legalAdvisorRoleId = 17;
export const adminRoleId = 19;
export const curatorRoleId = 20;

export const applicationStatusType = [
	"created",
	"checking",
	"finalize",
	"preparation",
	"consideration",
	"billPreparation",
	"letterPreparation",
	"sendingLetter",
	"paymentWaiting",
	"closed",
];

export const NOTICE_LIMIT = 20;
