import { ListItemDetail } from "components/ListItemDetail";
import { tabsGenerate } from "utils/helpers";

const PresidiumDetailPage = () => (
	<ListItemDetail
		type='application'
		url='protocols-presidium'
		api='presidium'
		navigation={tabsGenerate("protocol")}
	/>
);

export default PresidiumDetailPage;
